{
  "name": "computations-plant-plugin",
  "description": "Computations Plant Plug-in",
  "version": "0.1.9",
  "author": "Scheinder Electric",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular-material-components/datetime-picker": "^16.0.1",
    "@angular-material-components/moment-adapter": "^16.0.1",
    "@angular/animations": "^18.1.3",
    "@angular/cdk": "^18.1.3",
    "@angular/common": "^18.1.3",
    "@angular/compiler": "^18.1.3",
    "@angular/core": "^18.1.3",
    "@angular/forms": "^18.1.3",
    "@angular/material": "^18.1.3",
    "@angular/material-moment-adapter": "^18.1.3",
    "@angular/platform-browser": "^18.1.3",
    "@angular/platform-browser-dynamic": "^18.1.3",
    "@angular/router": "^18.1.3",
    "@fontsource/material-icons": "^5.1.0",
    "@fontsource/roboto": "^5.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/offscreencanvas": "^2019.7.3",
    "blockly": "^11.1.1",
    "chart.js": "^4.4.3",
    "chartjs-adapter-date-fns": "^3.0.0",
    "date-fns": "^3.6.0",
    "eslint-plugin-sonarjs": "^2.0.2",
    "moment": "^2.30.1",
    "ngx-daterangepicker-material": "^6.0.4",
    "oidc-client": "^1.11.5",
    "oidc-client-ts": "^3.0.1",
    "rxjs": "^7.8.1",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.3",
    "@angular/cli": "^18.1.3",
    "@angular/compiler-cli": "^18.1.3",
    "@eslint/compat": "^1.1.1",
    "@eslint/eslintrc": "^3.1.0",
    "@eslint/js": "^9.9.1",
    "@types/chart.js": "^2.9.41",
    "@types/jasmine": "^5.1.4",
    "@types/node": "^22.1.0",
    "@typescript-eslint/eslint-plugin": "^8.2.0",
    "@typescript-eslint/parser": "^8.2.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.9.1",
    "eslint-plugin-import": "^2.29.1",
    "globals": "^15.9.0",
    "jasmine-core": "^5.2.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "postcss": "^8.4.41",
    "tailwindcss": "^3.4.7",
    "typescript": "~5.5.4"
  }
}
