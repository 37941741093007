import libraryBlocks from './blocks_compressed.js';
export const {
  blocks,
  lists,
  logic,
  loops,
  math,
  procedures,
  texts,
  variables,
  variablesDynamic
} = libraryBlocks;