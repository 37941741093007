import {Component, EventEmitter, Input, OnInit, Output, inject} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavContainer, MatSidenav, MatSidenavContent} from '@angular/material/sidenav';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {I18nService} from '~/services/common/i18n.service';
import {AsyncPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatNavList} from '@angular/material/list';
import {NavigationEnd, Router, RouterLink, RouterOutlet} from '@angular/router';
import {OidcService} from '~/services/oidc.service';
import {AutocompleteComponent} from '~/components/form/autocomplete/autocomplete.component';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NamespacesService} from '~/services/namespaces.service';
import {AutocompleteFormComponent} from '~/components/form/autocomplete-form/autocomplete-form.component';
import {NamespaceDialog} from '~/components/toolbar/namespace-dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {LoaderService} from '~/services/loader.service';

/**
 * @title ToolbarComponent
 */
@Component({
  selector: 'toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrl: 'toolbar.component.scss',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule, MatSidenavContainer, MatSidenav, MatSidenavContent, MatMenuTrigger,
    MatMenu, MatMenuItem, TranslateModule, NgForOf, MatNavList, NgClass, RouterOutlet, RouterLink, NgIf, AutocompleteComponent,
    ReactiveFormsModule, AutocompleteFormComponent, AsyncPipe, NamespaceDialog, MatProgressBarModule],
})
export class ToolbarComponent implements OnInit {
  @Input() isMobile: boolean;
  @Output() toggleSent: EventEmitter<any> = new EventEmitter<any>();
  fullName: string;
  email: string;
  sendToggle() {
    this.toggleSent.emit();
  }

  languages: any[] = environment.supportedLanguages;
  isLoggedIn: boolean;

  form: FormGroup<any>;
  loader = inject(LoaderService);
  oidc = inject(OidcService);
  i18nService = inject(I18nService);
  oidcService = inject(OidcService);
  namespacesService = inject(NamespacesService);
  router = inject(Router);

  ngOnInit() {
    this.oidc.authSignal.subscribe(value => {
      this.isLoggedIn = this.oidcService.isLoggedIn();
      const userStr = localStorage.getItem(this.oidcService.LS_KEY_AUTH);
      if (userStr) {
        const user = JSON.parse(userStr);
        this.fullName = `${user?.profile?.given_name} ${user?.profile?.family_name}`;
        // this.email = user?.profile?.given_name.toLowerCase() + '.' + user?.profile?.family_name?.toLowerCase() + '@se.com';
        this.email = user?.profile?.email;
      }
    });
    this.router.events.subscribe(event => {
      const nag = event as NavigationEnd;
      const url = nag.url;
      this.namespacesService.disabled.next(url?.includes('edit') ?? false);
    });
  }

  logout() {
    this.oidcService.logout();
  }
  changeLang(lang: string) {
    this.i18nService.language = lang;
  }
}
