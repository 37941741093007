import {inject, Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { includes } from 'lodash';

const languageKey = 'language';

/**
 * Function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param {string} s The string to extract for translation.
 * @return {string} The same string.
 */
export function extract(s: string) {
  return s;
}

@Injectable()
export class I18nService {

  defaultLanguage: string;
  supportedLanguages: any[];
  translateService = inject(TranslateService);

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param {!string} defaultLanguage The default language to use.
   * @param {Array.<String>} supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: any[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;

    this.language = null;
  }

  getTranslation(key: string): string {
    this.translateService.use(this.language);
    return this.translateService.instant(key);
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param {string} language The IETF language code to set.
   */
  set language(language: string) {
    language = language || localStorage.getItem(languageKey) || this.defaultLanguage;
    // const isSupportedLanguage = includes(this.supportedLanguages.map((item: any) => item.code), language);
    //
    // // Fallback if language is not supported
    // if (!isSupportedLanguage) {
    //   language = this.defaultLanguage;
    // }

    localStorage.setItem(languageKey, language);
    this.translateService.use(language);
  }

  /**
   * Gets the current language.
   * @return {string} The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }
}
