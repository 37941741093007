<header style="position: fixed; z-index: 100; width: 100%;">
  <div style="display: block; position: relative; z-index: 50;background-color: white;">
    <mat-toolbar class="mat-elevation-z4" style="height: 70px;">
      <a style="text-decoration: none; color: #000000e6;" href="/">
        <h1 class="header-title" tabindex="-1">
          <img src="assets/img/EcoStruxure-Logo.svg" class="logo" alt="EcoStruxure Logo">
          <br>
          <span class="logo-text"><b>{{'Plant Plug-In' | translate}}</b> - {{'Computations' | translate}}</span>
       </h1>
      </a>
      <span class="spacer"></span>
      <namespace-dialog />

  <!--    LANG -->
  <!--    <button mat-icon-button [matMenuTriggerFor]="langMenu" class="example-icon account-icon" aria-label="icon-button account icon">-->
  <!--      <mat-icon fontIcon="lang"></mat-icon>-->
  <!--    </button>-->
  <!--    <mat-menu #langMenu="matMenu" xPosition="before" >-->
  <!--      <button  *ngFor="let language of languages" mat-menu-item (click)="changeLang(language.code)">{{ language.name | translate }}</button>-->
  <!--    </mat-menu>-->

      <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="example-icon account-icon" aria-label="icon-button account icon">
        <mat-icon fontIcon="account_circle"></mat-icon>
      </button>
      <mat-menu #accountMenu="matMenu" xPosition="before">
        <button mat-menu-item><mat-icon fontIcon="account_circle"></mat-icon>
          <br>
          <div>{{fullName}}</div>
          <small>{{email}}</small>
          <br>
          <br>
        </button>
        <hr/>
        @if (isLoggedIn) {
          <button  mat-menu-item (click)="logout()"><mat-icon fontIcon="logout"></mat-icon>{{ 'Sign out' | translate }}</button>
        }
      </mat-menu>
      <button *ngIf="isMobile" (click)="sendToggle()" mat-icon-button aria-label="icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
  </div>
  <mat-progress-bar *ngIf="loader.isLoading()" mode="query"></mat-progress-bar>
</header>
